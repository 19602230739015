<template>
  <div id="my_account">
    <div class="info">
      <div class="avatar">
        <img v-if="userInfo.touxiang" :src="userInfo.touxiang" alt="">
        <img v-else src="@/assets/image/PersonalCenter/MyAccount/userAvatar.png" alt="">
        <div v-if="auth_state === 0" class="real_name">未实名</div>
        <div v-else class="real_name">已实名</div>
      </div>
      <div class="info_content">
        <div class="info_content_text">
          <div id="userName">{{ this.$store.state.userData.nickname }}</div>
          <div id="balance">账户余额：<span>¥<strong>{{ userInfo.freeAmount }}</strong></span></div>
          <div id="frozenAmount">冻结余额：¥{{ userInfo.frozenAmount }}</div>
        </div>
        <div class="info_content_btn">
          <div class="modify_info">
            <span><img src="@/assets/image/PersonalCenter/MyAccount/modify.png" alt=""></span>
            <span @click="modifyInfo">修改信息</span>
          </div>
          <div class="view_details">
            <span><img src="@/assets/image/PersonalCenter/MyAccount/viewDetails.png" alt=""></span>
            <span @click="viewDetails">查看明细</span>
          </div>
        </div>
      </div>
      <div class="btn_ground">
        <div class="recharge" @click="clickRecharge">我要充值
          <div class="inDev">
            <div class="inDev_tip">
              <img src="@/assets/image/PersonalCenter/MyAccount/deving.png" alt="">
              <div>平台账号充值提现开发中，目前<span>支持至银行、支付宝、微信放款</span>，财务将会在交易完成后放宽到您指定的账户～</div>
            </div>
          </div>
        </div>
        <div class="withdraw" @click="clickWithdraw">我要提现
          <div class="inDev">
            <div class="inDev_tip">
              <img src="@/assets/image/PersonalCenter/MyAccount/deving.png" alt="">
              <div>平台账号充值提现开发中，目前<span>支持至银行、支付宝、微信放款</span>，财务将会在交易完成后放宽到您指定的账户～</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="seller_info">
      <div class="title">
        <div class="title_left">
          <div class="title_item">
          </div>
          我是卖家
        </div>
        <router-link class="title_right" :to="{name:'AccountSell'}" @mouseenter.native="accountSell=true"
                     @mouseleave.native="accountSell=false">
          <img v-show="!accountSell" src="@/assets/image/PersonalCenter/MyAccount/back.png" alt="">
          <img v-show="accountSell" src="@/assets/image/PersonalCenter/MyAccount/backHover.png" alt="">
          <span>去卖号</span>
        </router-link>
        <!--        <div class="title_right" @click="clickSellItem('全部')">我要卖号</div>-->
      </div>
      <div class="seller_info_content">
        <!-- 降价请求 -->
        <router-link :to="{name:'Seller'}" class="seller_info_content_item">
          <div class="item_num">{{ userInfo.productTotal }}</div>
          <div class="item_text">发布的账号</div>
        </router-link>
        <!-- 降价请求 -->
        <router-link :to="{name:'SellerGold'}" class="seller_info_content_item">
          <div class="item_num">{{ userInfo.goldTotal }}</div>
          <div class="item_text">发布的金币</div>
        </router-link>
        <!-- 降价请求 -->
        <router-link :to="{name:'SellerEquip'}" class="seller_info_content_item">
          <div class="item_num">{{ userInfo.equipTotal }}</div>
          <div class="item_text">发布的装备</div>
        </router-link>
        <!-- 降价请求 -->
        <router-link :to="{name:'SellerBargain'}" class="seller_info_content_item">
          <div class="item_num">{{ userInfo.priceRequestSale }}</div>
          <div class="item_text">降价请求</div>
        </router-link>
      </div>
    </div>
    <div class="buyer_info">
      <div class="title">
        <div class="title_left">
          <div class="title_item">
          </div>
          我是买家
        </div>
        <router-link class="title_right" :to="{name:'AllGame'}" @mouseenter.native="AllGame=true"
                     @mouseleave.native="AllGame=false">
          <img v-show="!AllGame" src="@/assets/image/PersonalCenter/MyAccount/back.png" alt="">
          <img v-show="AllGame" src="@/assets/image/PersonalCenter/MyAccount/backHover.png" alt="">
          <span>去买号</span>
        </router-link>
        <!--        <div class="title_right" @click="clickBuyItem('已成交')">我要买号</div>-->
      </div>
      <div class="buyer_info_content">
        <!-- 账号订单 -->
        <router-link :to="{name:'Buyer'}" class="buyer_info_content_item">
          <div class="item_num">{{ userInfo.buyProduct }}</div>
          <div class="item_text">账号订单</div>
        </router-link>
        <!-- 金币订单 -->
        <router-link :to="{name:'BuyerGold'}" class="buyer_info_content_item">
          <div class="item_num">{{ userInfo.buyGold }}</div>
          <div class="item_text">金币订单</div>
        </router-link>
        <!-- 装备订单 -->
        <router-link :to="{name:'BuyerEquip'}" class="buyer_info_content_item">
          <div class="item_num">{{ userInfo.buyEquip }}</div>
          <div class="item_text">装备订单</div>
        </router-link>
        <!-- 我的收藏 -->
        <router-link :to="{name:'MyCollection'}" class="buyer_info_content_item">
          <div class="item_num">{{ userInfo.collect }}</div>
          <div class="item_text">我的收藏</div>
        </router-link>
        <!-- 发出的求降 -->
        <router-link :to="{name:'BuyerBargain'}" class="buyer_info_content_item">
          <div class="item_num">{{ userInfo.priceRequestBuy }}</div>
          <div class="item_text">发出的求降价</div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import {apiuserInfo, apiuserInformation} from "@/request/API";

export default {
  name: '',
  props: {},
  components: {},
  data() {
    return {
      //用户信息
      userInfo: {},
      //实名认证
      auth_state: '',
      accountSell: false,
      AllGame: false,
    }
  },
  methods: {
    //修改信息点击事件
    modifyInfo() {
      this.$router.push({
        path: '/personalCenter/personalInfo'
      });
    },
    //查看明细点击事件
    viewDetails() {
      this.$router.push({
        path: '/personalCenter/financialCenter/moneyDetails'
      });
    },
    //我要充值点击事件
    clickRecharge() {
      this.$router.push({
        path: '/personalCenter/financialCenter',
      });
    },
    //我要提现点击事件
    clickWithdraw() {
      this.$router.push({
        path: '/personalCenter/financialCenter/withdraw',
      });
    },
    //我是卖家item点击事件
    clickSellItem(param, index) {
      this.$router.push({
        path: '/personalCenter/seller',
        name: 'Seller',
        params: {
          selectItem: param,
          selectItemIndex: index,
        }
      });
    },
    //我是买家item点击事件
    clickBuyItem(param, index) {
      this.$router.push({
        path: '/personalCenter/buyer',
        name: 'Buyer',
        params: {
          selectItem: param,
          selectItemIndex: index,
        }
      });

    },
    //我的收藏item点击事件
    clickCollectionItem(param) {
      this.$router.push({
        path: '/personalCenter/myCollection',
        name: 'MyCollection',
        params: {
          selectItem: param
        }
      });

    },
    //计算开发倒计时天数
    getDevDays(dateString1, dateString2) {
      var startDate = dateString1;
      var endDate = Date.parse(dateString2);
      if (startDate > endDate) {
        return 0;
      }
      if (startDate == endDate) {
        return 1;
      }
      var days = (endDate - startDate) / (1 * 24 * 60 * 60 * 1000);
      return parseInt(days);
    },
    //初始化数据
    onLoad() {
      apiuserInformation({}).then(res => {
        this.userInfo = res.data
      })
      apiuserInfo({
        action: 'verified'
      }).then(res => {
        this.auth_state = res.data.auth_state
      })
    }
  },
  mounted() {
    //初始化数据
    this.onLoad()
  },
  watch: {},
  computed: {}
}
</script>

<style lang='less' scoped>
#my_account {
  height: 681px;
  background: url("../../../assets/image/PersonalCenter/MyAccount/background.png") no-repeat;
  padding: 46px 20px 0 20px;

  .info {
    height: 87px;
    //background: red;
    margin-bottom: 41px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .avatar {
      margin-left: 40px;
      width: 80px;
      height: 80px;
      border: 3px solid white;
      border-radius: 43px;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 43px;
      }

      .real_name {
        width: 70px;
        height: 20px;
        background: #FFFFFF;
        border-radius: 11px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 500;
        color: #283748;
        line-height: 20px;
        text-align: center;
        position: absolute;
        top: 63px;
        left: 6px;
      }
    }

    .info_content {
      margin-left: 31px;
      display: flex;
      flex-direction: row;

      .info_content_text {
        width: 235px;
        //margin-right: 30px;
        #userName {
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
        }

        #balance {
          font-size: 16px;
          font-family: PingFangSC-Thin, PingFang SC;
          font-weight: 300;
          color: #FFFFFF;
          line-height: 22px;

          span {
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #FCAD13;
            line-height: 28px;

            strong {
              font-size: 24px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 600;
              color: #FCAD13;
              line-height: 40px;
            }
          }
        }

        #frozenAmount {
          font-size: 16px;
          font-family: PingFangSC-Thin, PingFang SC;
          font-weight: 300;
          color: #FFFFFF;
          line-height: 22px;
        }
      }

      .info_content_btn {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        font-size: 14px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 20px;

        .modify_info {
          margin-bottom: 23px;
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;

          span:first-of-type {
            display: inline-block;
            width: 18px;
            height: 18px;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }

        .view_details {
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;

          span:first-of-type {
            display: inline-block;
            width: 18px;
            height: 18px;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }

    }

    .btn_ground {
      margin-left: 232px;
      display: flex;
      flex-direction: column;

      div {
        width: 200px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
        border-radius: 28px;
        font-size: 18px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 400;
      }

      .recharge,.withdraw {
        background: #FFFFFF;
        margin-bottom: 6px;
        color: #44557C;
        position: relative;

        .inDev {
          width: 388px;
          height: 122px;
          display: none;
          position: absolute;
          top: -38px;
          left: -350px;
          // padding: 0 0 29px 0;
          background: url("../../../assets/image/PersonalCenter/MyAccount/inDev2.png") no-repeat;
          background-size: contain;

          .inDev_tip {
            width: 100%;
            font-size: 14px;
            font-family: PingFangSC-Thin, PingFang SC;
            font-weight: 300;
            color: #9A9A9A;
            line-height: 20px;
            height: 122px;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            margin: 15px 0 0 20px;
            img{
              width: 125px;
              height: 88px;
            }
            div{
              width: 193px;
              font-size: 14px;
              line-height: 24px;
              text-align: left;
              margin-left: 15px;
            }
            span {
              color: #FCAD13;
              font-family: PingFangSC-Medium, PingFang SC;
            }
          }
        }

      }

      .recharge:hover,.withdraw:hover {
        .inDev {
          display: block;
        }
      }

      .withdraw {
        border: 1px solid #FFFFFF;
        color: #FFFFFF;
        background: transparent;
      }
    }
  }

  .seller_info {
    padding: 0 40px;
    height: 200px;
    margin-bottom: 10px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    display: flex;
    flex-direction: column;

    .seller_info_content {
      display: flex;
      flex-direction: row;
      margin-left: 51px;

      .seller_info_content_item {
        width: 80px;
        height: 73px;
        margin-right: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;

        .item_num {
          font-size: 30px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #000000;
          line-height: 42px;
          margin-bottom: 9px;
        }

        .item_text {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #000000;
          line-height: 22px;
        }
      }

      .seller_info_content_item:last-of-type {
        margin-right: 0px;
      }
    }
  }

  .buyer_info {
    padding: 0 40px;
    height: 200px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    display: flex;
    flex-direction: column;

    .buyer_info_content {
      display: flex;
      flex-direction: row;
      margin-left: 51px;

      .buyer_info_content_item {
        width: 80px;
        height: 73px;
        margin-right: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;

        .item_num {
          font-size: 30px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #000000;
          line-height: 42px;
          margin-bottom: 9px;
        }

        .item_text {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #000000;
          line-height: 22px;
          white-space: nowrap;
        }
      }

      .buyer_info_content_item:last-of-type {
        margin-right: 0px;
      }
    }
  }

  .title {
    margin: 30px 0 20px 0;
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 28px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .title_left {
      display: flex;
      flex-direction: row;
      align-items: center;

      .title_item {
        width: 6px;
        height: 20px;
        background: #000000;
        margin: 0 16px 0 0;

      }
    }

    .title_right {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      font-size: 14px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #8F8F8F;

      span {
        margin: 0 0 0 5px;
      }
    }

    .title_right:hover {
      color: #434ED6;
    }
  }
}
</style>
